const { Scene } = require('phaser');
const constants = require('../constants');

module.exports = class CombatEnd extends Scene {
    constructor() {
      super(constants.scene.COMBAT_END);
      this.messages = {
        win: [
          'PRINT THIS AND HANG IT UP ON YOUR WALL',
          'YOUR MOTHER MUST BE PROUD',
          'YOU MUST BE VEGAN',
          'ABOUT AS USELESS AS...',
          'GIVE YOURSELF A PAT ON THE BACK, NO ON ELSE WILL',
          'MUST GET INTO A LOT OF INTERNET FIGHTS',
          'YOUR INTERNET FRIENDS MUST BE PROUD'
        ],
        lose: [
          'THE DIFFICULTY WAS SET TO EASY',
          'YOU TRIED AND IT WASNT GOOD ENOUGH',
          'YOU MUST BE VEGAN',
        ]
      }

      // Stats
      // - Writers block
      // - Borderline dyslexic
      // - Pathetic
      // - Faster than 5 year old
      // - Needs more friends
      // - You’re nothing without spellcheck
    }
    init(data) {
      console.log('combatend')
      this.victory = data.victory
    }
    create() {


      const width = this.cameras.main.width;
      const height = this.cameras.main.height;

      const startButton = this.add.text(width/2, (height/2) + 80, 'MAIN MENU', { fontsize: '24px' }).setOrigin(0.5, 0.5).setInteractive();
      this.text = [
        this.add.text(20, 20, 'PROFANE NIGHTMARE'),
        this.add.text(20, 40, "Combat End"),

        this.add.text(width/2, (height/2) - 0, this.getMessage()).setOrigin(0.5, 0.5),

        startButton,
      ]

      startButton.on('pointerdown', () => {
        this.text.forEach(t => t.destroy());
        this.scene.start(constants.scene.MAIN_MENU);
      })
    }

    getMessage(victory){
      let prefix = 'YOU WIN!'
      let key = 'win'
      if (!this.victory) {
        prefix = 'YOU LOSE!'
        key = 'lose'
      }
      const message = this.messages[key][Math.floor(Math.random()*this.messages[key].length)];
      return `${prefix} ${message}`;
    }
  }