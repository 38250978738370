const { Game, Scale } = require('phaser');
const TagTextPlugin = require('phaser3-rex-plugins/dist/rextagtextplugin.min.js');

const constants = require('./constants')
const { resize } = require('./utils')

const Preload = require('./scenes/Preload')
const MainMenu = require('./scenes/MainMenu')
const Combat = require('./scenes/Combat')
const CombatEnd = require('./scenes/CombatEnd')

const config = {
  scale: {
    mode: Scale.FIT,
    autoCenter: Scale.CENTER_BOTH,
    width: constants.game.DEFAULT_WIDTH,
    height: constants.game.DEFAULT_HEIGHT
  },
  height: constants.game.DEFAULT_HEIGHT,
  backgroundColor: 0x000000,
  plugins: {
    global: [{
      key: 'rexTagTextPlugin',
      plugin: TagTextPlugin,
      start: true
    }],
    scene: []
  }
}

if (process.env.NODE_ENV !== 'production') {
  const DebugDraw = require('phaser-plugin-debug-draw');
  config.plugins.scene.push({ key: 'DebugDrawPlugin', plugin: DebugDraw, mapping: 'DebugDraw'});
}

window.addEventListener('load', () => {
  const game = new Game(config)

  window.addEventListener('resize', event => {
    // resize(game, constants.game)
  })
  // resize(game, constants.game)

  game.scene.add(constants.scene.PRELOAD, Preload);
  game.scene.start(constants.scene.PRELOAD, { someData: '...arbitrary data' });

  game.scene.add(constants.scene.MAIN_MENU, MainMenu);
  game.scene.add(constants.scene.COMBAT, Combat);
  game.scene.add(constants.scene.COMBAT_END, CombatEnd);
})