module.exports.resize = (game, config) => {
  const w = window.innerWidth
  const h = window.innerHeight

  let width = config.DEFAULT_WIDTH
  let height = config.DEFAULT_HEIGHT
  let maxWidth = config.MAX_WIDTH
  let maxHeight = config.MAX_HEIGHT
  let scaleMode = config.SCALE_MODE

  let scale = Math.min(w / width, h / height)
  let newWidth = Math.min(w / scale, maxWidth)
  let newHeight = Math.min(h / scale, maxHeight)

  let defaultRatio = config.DEFAULT_WIDTH / config.DEFAULT_HEIGHT
  let maxRatioWidth = config.MAX_WIDTH / config.DEFAULT_HEIGHT
  let maxRatioHeight = config.DEFAULT_WIDTH / config.MAX_HEIGHT

  // smooth scaling
  let smooth = 1
  if (scaleMode === 'SMOOTH') {
    const maxSmoothScale = 1.15
    const normalize = (value, min, max) => {
      return (value - min) / (max - min)
    }
    if (width / height < w / h) {
      smooth =
        -normalize(newWidth / newHeight, defaultRatio, maxRatioWidth) / (1 / (maxSmoothScale - 1)) + maxSmoothScale
    } else {
      smooth =
        -normalize(newWidth / newHeight, defaultRatio, maxRatioHeight) / (1 / (maxSmoothScale - 1)) + maxSmoothScale
    }
  }

  // resize the game
  game.scale.resize(newWidth * smooth, newHeight * smooth)

  // scale the width and height of the css
  game.canvas.style.width = newWidth * scale + 'px'
  game.canvas.style.height = newHeight * scale + 'px'

  // center the game with css margin
  game.canvas.style.marginTop = `${(h - newHeight * scale) / 2}px`
  game.canvas.style.marginLeft = `${(w - newWidth * scale) / 2}px`
}