const { GameObjects } = require('phaser');

const insults = require('../insults');

const enemyTypes = {
  blackmage: {
    idle: {
      key: 'blackmage-idle-a',
      frames: {
        start: 1,
        end: 34,
        prefix: 'blackmage/idle_a_',
      }
    },
    hit: {
      key: 'blackmage-hit-a',
      frames: {
        start: 1,
        end: 12,
        prefix: 'blackmage/hit_a_',
      }
    },
    attackLight: {
      key: 'blackmage-skill-c',
      frames: {
        start: 1,
        end: 23,
        prefix: 'blackmage/skill_c_',
      }
    },
    attackHeavy: {
      key: 'blackmage-skill-b',
      frames: {
        start: 1,
        end: 23,
        prefix: 'blackmage/skill_b_',
      }
    },
    death: {
      key: 'blackmage-death',
      frames: {
        start: 1,
        end: 15,
        prefix: 'blackmage/death_',
      }
    }
  },
  polymer: {
    idle: {
      key: 'polymer-idle-a',
      frames: {
        start: 1,
        end: 30,
        prefix: 'polymer/idle_a_',
      }
    },
    hit: {
      key: 'polymer-hit-a',
      frames: {
        start: 1,
        end: 12,
        prefix: 'polymer/hit_a_',
      }
    },
    attackLight: {
      key: 'polymer-skill-a',
      frames: {
        start: 1,
        end: 14,
        prefix: 'polymer/skill_a_',
      }
    },
    attackHeavy: {
      key: 'polymer-skill-b',
      frames: {
        start: 1,
        end: 23,
        prefix: 'polymer/skill_b_',
      }
    },
    death: {
      key: 'polymer-death',
      frames: {
        start: 1,
        end: 15,
        prefix: 'polymer/death_',
      }
    }
  },
  werewolf: {
    idle: {
      key: 'werewolf-idle-a',
      frames: {
        start: 1,
        end: 24,
        prefix: 'werewolf/idle_a_',
      }
    },
    hit: {
      key: 'werewolf-hit-a',
      frames: {
        start: 1,
        end: 12,
        prefix: 'werewolf/hit_a_',
      }
    },
    attackLight: {
      key: 'werewolf-skill-b',
      frames: {
        start: 1,
        end: 27,
        prefix: 'werewolf/skill_b_',
      }
    },
    attackHeavy: {
      key: 'werewolf-skill-a',
      frames: {
        start: 1,
        end: 32,
        prefix: 'werewolf/skill_a_',
      }
    },
    death: {
      key: 'werewolf-death',
      frames: {
        start: 1,
        end: 15,
        prefix: 'werewolf/death_',
      }
    }
  }
}

module.exports = class Enemy extends GameObjects.Container {
  constructor(scene, x, y, children, type) {
    super(scene, x, y, children);

    this.maxHealth = 100;
    this.health = this.maxHealth;
    this.attacks = insults;

    const types = Object.keys(enemyTypes)
    if (!type || !types.includes(type)) {
      type = types[Math.floor(Math.random()*types.length)]
    }

    this.conf = enemyTypes[type]
    if (!this.conf) {
      throw new Error(`Unknown enemy type ${type}`)
    }
    const animConf = { zeroPad: 3, suffix: '.png' };
    scene.anims.create({
      key: this.conf.idle.key,
      frames: scene.anims.generateFrameNames('sheet', { ...this.conf.idle.frames, ...animConf }),
      repeat: -1
    });
    scene.anims.create({
      key: this.conf.hit.key,
      frames: scene.anims.generateFrameNames('sheet', { ...this.conf.hit.frames, ...animConf }),
      repeat: 0
    });
    scene.anims.create({
      key: this.conf.attackLight.key,
      frames: scene.anims.generateFrameNames('sheet', { ...this.conf.attackLight.frames, ...animConf }),
      repeat: 0
    });
    scene.anims.create({
      key: this.conf.attackHeavy.key,
      frames: scene.anims.generateFrameNames('sheet', { ...this.conf.attackHeavy.frames, ...animConf }),
      repeat: 0
    });
    scene.anims.create({
      key: this.conf.death.key,
      frames: scene.anims.generateFrameNames('sheet', { ...this.conf.death.frames, ...animConf }),
      repeat: 0
    });

    this.enemy = scene.add.sprite(x, y).setFlipX(true);
    this.enemy.setScale(1).play(this.conf.idle.key);
    this.enemy.on('animationcomplete', this.animationComplete.bind(this));
    this.drawHealthBar(scene, x, y);
  }

  attackHeavy(cb) {
    this.animationCallback = cb;
    this.animationCallbackKey = this.conf.attackHeavy.key;
    this.enemy.play(this.conf.attackHeavy.key)
  }

  attackLight(cb) {
    this.animationCallback = cb;
    this.animationCallbackKey = this.conf.attackLight.key;
    this.enemy.play(this.conf.attackLight.key)
  }

  takeDamage (damage, cb) {
    this.health -= damage;
    if (this.health <= 0) this.health = 0;
    this.health = Math.round(this.health);
    this.updatehealthBar();
    console.log(`OWWW you prick, that hurt ${this.health}`)
    this.animationCallback = cb;
    this.animationCallbackKey = this.conf.hit.key;
    this.enemy.play(this.conf.hit.key, false);
  }

  death(cb) {
    this.animationCallback = () => {
      setTimeout(cb, 3000)
    };
    this.animationCallbackKey = this.conf.death.key;
    this.enemy.play(this.conf.death.key)
  }

  animationComplete(event) {
    // console.log(event);
    if (!this.animationCallback || !this.animationCallbackKey) return;
    if (event.key === this.animationCallbackKey) {
      this.animationCallback();
      this.animationCallback = null;
      this.animationCallbackKey = null;
    }
    if (event.key !== this.conf.death.key) {
      this.enemy.play(this.conf.idle.key)
    }
  }

  getLight() {
    return this.attacks.light[Math.floor(Math.random()*this.attacks.light.length)]
  }

  getHeavy() {
    return this.attacks.heavy[Math.floor(Math.random()*this.attacks.heavy.length)]
  }

  drawHealthBar(scene, x, y) {
    const progressBox = scene.add.graphics()
    this.progressBarX = x - (this.enemy.width / 2) - 50;
    this.progressBarY = y - (this.enemy.height / 2) - 150;
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(this.progressBarX, this.progressBarY, this.enemy.width / 2, 12);
    this.healthbar = scene.add.graphics();
    this.healthText = scene.make.text({
      x: this.progressBarX + (this.enemy.width / 4),
      y:this.progressBarY + 6,
      text: `${this.health}/${this.maxHealth}`,
      style: {
        fontSize: 8
      }
    });
    this.healthText.setOrigin(.5, .5);
    this.updatehealthBar();
  }

  updatehealthBar() {
    const value = this.health / this.maxHealth;
    const width = ((this.enemy.width / 2) - 4 )* value;
    this.healthbar.clear();
    this.healthbar.fillStyle(0xff0000, 1);
    this.healthbar.fillRect(this.progressBarX + 2, this.progressBarY + 2, width, 8);
    // this.healthbar.setDepth(1);
    this.healthText.setText(`${this.health}/${this.maxHealth}`);
  }
}