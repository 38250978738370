const { Scene } = require('phaser');
const constants = require('../constants');

module.exports = class Preload extends Scene {
  constructor() {
    super(constants.scene.PRELOAD);

    this.messages = [
      'WORK SLOW THESE DAYS?',
      'GOT NOTHING BETTER TO DO?',
      'BET YOU LOVE GETTING INTO INTERNET FIGHTS',
      'GET A BETTER JOB AND PAY FOR A FASTER CONNECTION',
      'YOUR INTERNET PROVIDER MUST LOVE YOU',
    ]
  }
  preload() {
    const width = this.cameras.main.width;
    const height = this.cameras.main.height;

    const progressBarX = (width / 2) - 160;
    const progressBarY = (height / 2) - 25;

    const progressBar = this.add.graphics();
    const progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(progressBarX, progressBarY, 320, 50);

    const loadingText = this.make.text({
        x: width / 2,
        y: height / 2 - 50,
        text: 'Loading...',
    });
    loadingText.setOrigin(0.5, 0.5);

    const percentText = this.make.text({
        x: width / 2,
        y: height / 2,
        text: '0%',
    });
    percentText.setOrigin(0.5, 0.5);

    const assetText = this.make.text({
        x: width / 2,
        y: height / 2 + 50,
        text: '',
    });

    assetText.setOrigin(0.5, 0.5);

    this.load.on('progress', value => {
      const percentLoaded = Math.round(value * 100);
      percentText.setText(percentLoaded + '%');
      progressBar.clear();
      progressBar.fillStyle(0xffffff, 1);
      progressBar.fillRect(progressBarX + 10, progressBarY + 10, 300 * value, 30);
    });

    assetText.setText(this.messages[Math.floor(Math.random()*this.messages.length)]);

    this.load.on('complete', () => {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();

      percentText.setText('PROFANE NIGHTMARE');
      setTimeout(() => {
        percentText.destroy();
        assetText.destroy();
        this.scene.start(constants.scene.MAIN_MENU);
        // this.scene.start(constants.scene.COMBAT);
      }, 2000)
    });

    this.load.path = 'assets/';
    this.load.multiatlas('sheet', 'profane-nightmare.json');
  }
}