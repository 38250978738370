const { GameObjects } = require('phaser');

const insults = require('../insults');

const animConf = {
  idle: {
    key: 'mage/idle_a_',
    frames: {
      start: 1,
      end: 30,
      prefix: 'mage/idle_a_',
    }
  },
  hit: {
    key: 'mage-hit-a',
    frames: {
      start: 1,
      end: 12,
      prefix: 'mage/hit_a_',
    }
  },
  attackLight: {
    key: 'mage-skill-a',
    frames: {
      start: 1,
      end: 19,
      prefix: 'mage/skill_a_',
    }
  },
  attackHeavy: {
    key: 'mage-skill-b',
    frames: {
      start: 1,
      end: 18,
      prefix: 'mage/skill_b_',
    }
  },
  death: {
    key: 'mage-death',
    frames: {
      start: 1,
      end: 15,
      prefix: 'mage/death_',
    }
  }
}

module.exports = class Player extends GameObjects.Container {
  constructor(scene, x, y, children) {
    super(scene, x, y, children);

    this.scene = scene;
    this.maxHealth = 100;
    this.health = this.maxHealth;
    this.attacks = insults;

    const animConfig = { zeroPad: 3, suffix: '.png' };
    scene.anims.create({
      key: animConf.idle.key,
      frames: scene.anims.generateFrameNames('sheet', { ...animConf.idle.frames, ...animConfig }),
      repeat: -1
    });
    scene.anims.create({
      key: animConf.hit.key,
      frames: scene.anims.generateFrameNames('sheet', { ...animConf.hit.frames, ...animConfig }),
      repeat: 0
    });
    scene.anims.create({
      key: animConf.attackLight.key,
      frames: scene.anims.generateFrameNames('sheet', { ...animConf.attackLight.frames, ...animConfig }),
      repeat: 0
    });
    scene.anims.create({
      key: animConf.attackHeavy.key,
      frames: scene.anims.generateFrameNames('sheet', { ...animConf.attackHeavy.frames, ...animConfig }),
      repeat: 0
    });
    scene.anims.create({
      key: animConf.death.key,
      frames: scene.anims.generateFrameNames('sheet', { ...animConf.death.frames, ...animConfig }),
      repeat: 0
    });

    this.player = scene.add.sprite(x, y).setScale(0.7).play(animConf.idle.key);
    this.player.on('animationcomplete', this.animationComplete.bind(this));

    this.drawHealthBar(scene, x, y);
  }

  displayOnly(scale) {
    this.player.setScale(scale);

    //hide healthbar
    this.progressBox.setVisible(false);
    this.healthbar.setVisible(false);
    this.healthText.setVisible(false);
  }

  animationComplete(event) {
    // console.log(event);
    if (!this.animationCallback || !this.animationCallbackKey) return;
    if (event.key === this.animationCallbackKey) {
      this.animationCallback();
      this.animationCallback = null;
      this.animationCallbackKey = null;
    }
    if (event.key !== animConf.death.key) {
      this.player.play(animConf.idle.key)
    }
  }

  attackHeavy(cb) {
    this.animationCallback = cb;
    this.animationCallbackKey = animConf.attackHeavy.key;
    this.player.play(animConf.attackHeavy.key)
  }

  attackLight(cb) {
    this.animationCallback = cb;
    this.animationCallbackKey = animConf.attackLight.key;
    this.player.play(animConf.attackLight.key)
  }

  death(cb) {
    this.animationCallback = () => {
      setTimeout(cb, 3000)
    };
    this.animationCallbackKey = animConf.death.key;
    this.player.play(animConf.death.key)
  }

  takeDamage (damage, cb) {
    this.health -= damage;
    if (this.health <= 0) this.health = 0;
    this.health = Math.round(this.health);
    this.updatehealthBar();
    console.log(`OWWW you prick, that hurt ${this.health}`)
    this.animationCallback = cb;
    this.animationCallbackKey = animConf.hit.key;
    this.player.play(animConf.hit.key, false);
  }

  getLight() {
    return this.attacks.light[Math.floor(Math.random()*this.attacks.light.length)]
  }

  getHeavy() {
    return this.attacks.heavy[Math.floor(Math.random()*this.attacks.heavy.length)]
  }

  drawHealthBar(scene, x, y) {
    this.progressBox = scene.add.graphics();
    this.progressBarX = x;
    this.progressBarY = y - (this.player.height / 2) - 50;
    this.progressBox.fillStyle(0x222222, 0.8);
    this.progressBox.fillRect(this.progressBarX, this.progressBarY, this.player.width / 2, 12);
    this.healthbar = scene.add.graphics();
    this.healthText = scene.make.text({
      x: this.progressBarX + (this.player.width / 4),
      y:this.progressBarY + 6,
      text: `${this.health}/${this.maxHealth}`,
      style: {
        fontSize: 8
      }
    });
    this.healthText.setOrigin(.5, .5);
    this.updatehealthBar();
  }

  updatehealthBar() {
    const value = this.health / this.maxHealth;
    const width = ((this.player.width / 2) - 4 )* value;
    this.healthbar.clear();
    this.healthbar.fillStyle(0xff0000, 1);
    this.healthbar.fillRect(this.progressBarX + 2, this.progressBarY + 2, width, 8);
    // this.healthbar.setDepth(1);
    this.healthText.setText(`${this.health}/${this.maxHealth}`);
  }
}