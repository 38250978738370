const { Scene } = require('phaser');
const constants = require('../constants');

const Player = require('../actors/Player');
module.exports = class MainMenu extends Scene {
    constructor() {
      super(constants.scene.MAIN_MENU);
    }
    create() {
      console.log('mainmenu')
      const width = this.cameras.main.width;
      const height = this.cameras.main.height;

      this.player = new Player(this, 225, 325)
      this.player.displayOnly(0.4);

      const startButton = this.add.text(width/2, (height/2) + 80, 'START', { fontsize: '24px' }).setOrigin(0.5, 0.5).setInteractive();
      this.text = [
        this.titleText = this.add.text(20, 20, 'PROFANE NIGHTMARE'),
        this.sceneText = this.add.text(20, 40, 'Main Menu'),

        this.add.text(width/2, (height/2) - 80, 'A BATTLE SIMULATOR WHERE WORDS ARE YOUR WEAPONS').setOrigin(0.5, 0.5),
        this.add.text(width/2, (height/2) - 20, 'Type the phrase within the time window').setOrigin(0.5, 0.5),
        this.add.text(width/2, (height/2) - 0, ' to attack and block with bonuses').setOrigin(0.5, 0.5),
        this.add.text(width/2, (height/2) + 20, 'for speed and accuracy').setOrigin(0.5, 0.5),

        startButton,

        this.add.text(width/2, (height) - 40, 'development build - not feature complete').setOrigin(0.5, 0.5),
      ]

      startButton.on('pointerdown', () => {
        this.text.forEach(t => t.destroy());
        this.player.destroy();
        // this.enemy.destroy();
        this.scene.start(constants.scene.COMBAT);
        // this.scene.start(constants.scene.COMBAT_END, { victory: true });
      })
    }
  }