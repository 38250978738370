const allowedBackgrounds = ['dungeon', 'ghostship', 'piratepit', 'firepit',  'snakepit',]
//'desert', 'japan', 'toad', 'pirateisland',
module.exports = class Background {
  constructor(scene, name) {
    if (!name || !allowedBackgrounds.includes(name)) {
      name = allowedBackgrounds[Math.floor(Math.random()*allowedBackgrounds.length)]
    }
    console.log(name)
    const frames = scene.textures.get('sheet').frames

    this.layer1 = scene.add.sprite(0, 0, 'sheet', `background/${name}/Layer0.png`);
    this.layer1.setOrigin(0, 0).setScale(0.5)

    this.layer2 = scene.add.sprite(0, 0, 'sheet', `background/${name}/Layer1.png`);
    this.layer2.setOrigin(0, 0).setScale(0.5)

    const l3 = frames[`background/${name}/Layer2.png`]
    this.layer3 = scene.add.sprite(0, scene.game.config.height - l3.halfHeight, 'sheet', `background/${name}/Layer2.png`);
    this.layer3.setOrigin(0, 0).setScale(0.5).setDepth(1);
  }

  draw() {
    // const set = 'SG';
    // const files = this.scene.textures.get('backgrounds').frames
    // const sky = files[`${set}_bg.png`];
    // this.sky = this.scene.add.tileSprite(0, 0, sky.width, sky.height, 'backgrounds', `${set}_bg.png`);
    // this.sky.setOrigin(0, 0);

    // const ground = files[`${set}_ground_02.png`];
    // this.ground = this.scene.add.tileSprite(0, 375, this.scene.game.config.width * 2, ground.height, 'backgrounds', `${set}_ground_02.png`);
    // this.ground.setOrigin(0, 0);
    // // this.ground.tileScaleY = 0.4;
    // // this.ground.tileScaleX = 0.4;

    // this.mount1 = this.scene.add.sprite(50, 200, 'backgrounds', `${set}_mount_01.png`)
    // this.mount1.scaleX = 0.5;
    // this.mount1.scaleY = 0.5;

    // this.mount2 = this.scene.add.sprite(600, 200, 'backgrounds', `${set}_mount_02.png`)
    // this.mount2.scaleX = 0.5;
    // this.mount2.scaleY = 0.5;

    // this.mount3 = this.scene.add.sprite(1200, 200, 'backgrounds', `${set}_mount_03.png`)
    // this.mount3.scaleX = 0.8;
    // this.mount3.scaleY = 0.8;

    // this.mount4 = this.scene.add.sprite(900, 300, 'backgrounds', `${set}_mount_04.png`)
    // this.mount4.scaleX = 0.7;
    // this.mount4.scaleY = 0.7;

    // this.ship1 = this.scene.add.sprite(1300, 200, 'backgrounds', `${set}_ship_01.png`)
    // this.ship1.scaleX = 0.6;
    // this.ship1.scaleY = 0.6;

    // this.ship2 = this.scene.add.sprite(200, 200, 'backgrounds', `${set}_ship_02.png`)
    // this.ship2.scaleX = 0.4;
    // this.ship2.scaleY = 0.4;

    // const floor = files[`${set}_ground_shadow.png`];
    // this.floor = this.scene.add.tileSprite(0, 310, floor.width, floor.height, 'backgrounds', `${set}_ground_shadow.png`);
    // this.floor.setOrigin(0, 0);
    // this.floor.tileScaleY = 0.3;
    // this.floor.tileScaleX = 0.3;
  }
}