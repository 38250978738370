module.exports = {
  scene: {
    MAIN_MENU: 'MainMenu',
    COMBAT: 'Combat',
    COMBAT_END: 'CombatEnd',
    PRELOAD: 'Preload',
  },
  game: {
    DEFAULT_WIDTH: 1024,
    DEFAULT_HEIGHT: 576,
    MAX_WIDTH: 1536,
    MAX_HEIGHT: 864,
    SCALE_MODE: 'SMOOTH' // FIT OR SMOOTH
  }
}