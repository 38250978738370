const insults = [
  'Nice haircut',
  'Did you get dressed in the dark',
  'You were a mistake',
  'Nobody loves you',
  'Nobody would miss you',
  'You look like a before picture',
  'People just tolerate you',
  'Your family tree is a cactus',
  'You look like a visible fart',
  'Filthy mudblood', //harry potter
  'Do the splits', //adventure time
  'Dweeb',
  'You have a face for radio',
  'You are the celery of people',
  'You look like a microwaved piece of soap',
  'If you were a spice you would be flour',
  'One of the two of us is dumber than me',
  'You are the master of your own virginity',
  'You are impossible to underestimate',
  'The world has one too many of you',
  'I expected nothing and I am still dissapointed',
  'Your gene pool could use a little chlorine',
  'What does not kill you dissapoints me',
  // 'Where did you get thise clothes, the toilet store?', //anchorman
  // 'Your mother was a hamster and your father smelt of elderberries', //life if brian
  // 'Hey laser lips your mother was a snowblower', // Short Circuit
  // 'You dense irritating miniature beast of a burden', //shrek
  // 'Why you stuck up, half witted scruffy looking nerf herder', // start wars
  // 'You are so ugly, you could not even arouse suspicion',
  // 'You seem to be suffering from delusions of adequacy'
  'Your body is as evenly distributed as wealth',

]
module.exports = {
  light: insults,
  heavy: insults,
}