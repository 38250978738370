const { Scene } = require('phaser');
const constants = require('../constants');

const Background = require('../actors/Background');
const Player = require('../actors/Player');
const Enemy = require('../actors/Enemy');

const stages = {
  YOUR_TURN: 'YOUR_TURN',
  SELECT_ATTACK: 'SELECT_ATTACK',
  ATTACK_COUNTDOWN: 'ATTACK_COUNTDOWN',
  ATTACK: 'ATTACK',
  DO_DAMAGE: 'DO_DAMAGE',
  ENEMY_TURN: 'ENEMY_TURN',
  DEFEND_COUNTDOWN: 'DEFEND_COUNTDOWN',
  DEFEND: 'DEFEND',
  TAKE_DAMAGE: 'TAKE_DAMAGE',
  PLAYER_DEATH: 'PLAYER_DEATH',
  ENEMY_DEATH: 'ENEMY_DEATH'
}

module.exports = class Combat extends Scene {
  constructor() {
    super(constants.scene.COMBAT);
    this.stage = null
  }
  create() {
    console.log('combat')

    const width = this.cameras.main.width;
    const height = this.cameras.main.height;
    this.width = width;
    this.height = height;

    this.attackimer = 0;
    this.textConfig = {
      active: {
        color: 'red'
      },
      passive: {
        color: 'white'
      }
    };
    this.cursorKeys = this.input.keyboard.on('keyup', ({ key }) => {
      if (this.attackTimer > 0 && !this.attack.complete) {
        // success or miss
        if (key.toLowerCase() === this.attack.list[this.attack.index].toLowerCase()) {
          this.attack.index++
        } else {
          this.attack.missed++
        }

        // complete
        if(this.attack.index === this.attack.length) {
          this.attack.complete = true;
          this.attack.completedIn = this.attack.elapsedTime;
          this.attackTimer = 0;
        }
      }
    })

    new Background(this)
    this.add.text(20, 20, 'PROFANE NIGHTMARE');
    this.add.text(20, 40, 'Combat');

    this.player = new Player(this, 175, 350)
    this.enemy = new Enemy(this, 900, 350)

    // Turn indicator
    this.turnIndicatorText = this.add.text(width/2, height/2, 'YOUR TURN').setOrigin(0.5, 0.5).setVisible(false);

    // Select attack
    this.lightSelection = this.add.text(width/2, height/2, 'Light Attack').setOrigin(0.5, 0.5).setVisible(false);
    this.heavySelection = this.add.text(width/2, (height/2) + 20, 'Heavy Attack').setOrigin(0.5, 0.5).setVisible(false);

    // Countdown Display
    this.countdownDisplay = this.add.text(width/2, height/2, '3').setOrigin(0.5, 0.5).setVisible(false);

    // Attack
    this.attackDisplay = this.add.rexTagText(width/2, height/2, 'boo', { tags:this.textConfig }).setOrigin(0.5, 0.5).setVisible(false);
    // this.attackDisplay = this.add.text(width/2, height/2, 'boo').setOrigin(0.5, 0.5).setVisible(false);
    this.attackBox = this.add.graphics();
    this.attackTimerBar = this.add.graphics();
    // this.attackBox.setVis

    // Do damage
    // this.doDamage = this.add.text(width/2, height/2, 'boo').setOrigin(0.5, 0.5).setVisible(false);

    this.setStage(stages.YOUR_TURN);
  }

  setStage(stage) {
    console.log(`set Stage: ${stage}`)
    this.stage = stage;
    switch (this.stage){
      case stages.YOUR_TURN: {
        this.checkGameEnd();
        this.turnIndicatorTimer = 3000;
        this.takeDamageSent = false;
        this.doDamageSent = false;
        return;
      }
      case stages.SELECT_ATTACK: {
        this.attackOptionsTimer = 1000;
        // if (Math.random() > 0.5) {
        //   this.attack = this.parseAttackText(this.player.getHeavy());
        // } else {
        //   this.attack = this.parseAttackText(this.player.getLight());
        // }
        return;
      }
      case stages.ATTACK_COUNTDOWN: {
        if (Math.random() > 0.5) {
          this.attack = this.parseAttackText(this.player.getHeavy());
        } else {
          this.attack = this.parseAttackText(this.player.getLight());
        }
        this.countdownTimer = 3000;
        return;
      }
      case stages.ATTACK: {
        this.attackTimer = this.attack.timer;
        console.log(this.attack);
        return;
      }
      case stages.DO_DAMAGE: {
        this.doDamageSent = false;
        return;
      }
      case stages.ENEMY_TURN: {
        this.checkGameEnd();
        this.turnIndicatorTimer = 3000;
        return;
      }
      case stages.DEFEND_COUNTDOWN: {
        if (Math.random() > 0.5) {
          this.attack = this.parseAttackText(this.enemy.getHeavy());
        } else {
          this.attack = this.parseAttackText(this.enemy.getLight());
        }
        this.countdownTimer = 3000;
        return;
      }
      case stages.DEFEND: {
        this.attackTimer = this.attack.timer;
        console.log(this.attack);
        return;
      }
      case stages.TAKE_DAMAGE: {
        this.takeDamageSent = false;
        return;
      }
      case stages.PLAYER_DEATH:
      case stages.ENEMY_DEATH: {
        this.killSent = false
        return
      }
    }
  }

  update(time, delta) {
    switch (this.stage){
      case stages.YOUR_TURN: {
        return this.turnIndicator('YOUR TURN', delta, stages.ATTACK_COUNTDOWN);
      }
      case stages.SELECT_ATTACK: {
        return this.showAttackOptions(delta);
      }
      case stages.ATTACK_COUNTDOWN: {
        return this.showCountdown(delta, stages.ATTACK);
      }
      case stages.ATTACK: {
        return this.showAttack(delta, stages.DO_DAMAGE);
      }
      case stages.DO_DAMAGE: {
        return this.doDamage();
      }
      case stages.ENEMY_TURN: {
        return this.turnIndicator('ENEMY TURN', delta, stages.DEFEND_COUNTDOWN);
      }
      case stages.DEFEND_COUNTDOWN: {
        return this.showCountdown(delta, stages.DEFEND);
      }
      case stages.DEFEND: {
        return this.showAttack(delta, stages.TAKE_DAMAGE);
      }
      case stages.TAKE_DAMAGE: {
        return this.takeDamage();
      }
      case stages.PLAYER_DEATH: {
        return this.killPlayer();
      }
      case stages.ENEMY_DEATH: {
        return this.killEnemy();
      }
      default:
        return;
    }
  }

  handleInput() {
    // console.log(this.cursorKeys)
  }

  parseAttackText(attack) {
    const cpm = 60000 / 300;
    return {
      timer: attack.length * cpm,
      display: attack,
      index: 0,
      list: attack.split(''),
      length: attack.length,
      missed: 0,
      complete: false,
      completedIn: 0,
      elapsedTime: 0,
      baseDamage: this.randomNumberBetween(10, 15),
      damage: 0,
    }
  }

  turnIndicator(text, delta, stage) {
    this.turnIndicatorTimer -= delta;
    if (this.turnIndicatorTimer <= 0) {
      this.turnIndicatorText.setVisible(false);
      return this.setStage(stage);
    }
    this.turnIndicatorText.setText(text).setVisible(true);
  }

  showAttackOptions(delta) {
    this.attackOptionsTimer -= delta;
    if (this.attackOptionsTimer <= 0) {
      this.lightSelection.setVisible(false);
      this.heavySelection.setVisible(false);
      return this.setStage(stages.ATTACK_COUNTDOWN);
    }
    this.lightSelection.setVisible(true);
    this.heavySelection.setVisible(true);
  }

  showCountdown(delta, stage) {
    this.countdownTimer -= delta;
    if (this.countdownTimer <= 0) {
      this.countdownDisplay.setVisible(false);
      return this.setStage(stage);
    }
    const countDownDisplayValue = Math.ceil(this.countdownTimer / 1000);
    this.countdownDisplay.setText(countDownDisplayValue).setVisible(true);
  }

  showAttack(delta, stage) {
    this.attackTimer -= delta;
    if (this.attackTimer <= 0) {
      this.attackDisplay.setVisible(false);
      this.hideTimerBar();
      return this.setStage(stage);
    }
    if (!this.attack.complete) {
      this.attack.completedIn += delta;
    }
    this.attack.elapsedTime += delta;
    this.attackDisplay.setText(this.generateDisplayText()).setVisible(true);
    this.updateTimerBar(delta);
  }

  doDamage() {
    if (this.doDamageSent) return;
    this.doDamageSent = true;
    this.attack.damage = this.calculateDamage();
    console.log(`doing ${this.attack.damage} damage`)
    if(Math.random() > 0.5) {
      this.player.attackHeavy(() => {
        this.enemy.takeDamage(this.attack.damage, () => this.setStage(stages.ENEMY_TURN))
      });
    } else {
      this.player.attackLight(() => {
        this.enemy.takeDamage(this.attack.damage, () => this.setStage(stages.ENEMY_TURN))
      });
    }
  }

  randomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  takeDamage() {
    if (this.takeDamageSent) return;
    this.takeDamageSent = true;
    this.attack.damage = this.calculateDamage();
    this.attackValue = this.randomNumberBetween(5, 20);
    const blockDamage = this.attack.damage / 2;
    if (blockDamage > this.attackValue) {
      console.log('full block');
      this.attackValue = 0;
    } else {
      this.attackValue -= blockDamage;
    }
    console.log(`taking ${this.attackValue} damage`)
    if(Math.random() > 0.5) {
      this.enemy.attackHeavy(() => {
        this.player.takeDamage(this.attackValue, () => this.setStage(stages.YOUR_TURN))
      });
    } else {
      this.enemy.attackLight(() => {
        this.player.takeDamage(this.attackValue, () => this.setStage(stages.YOUR_TURN))
      });
    }
  }

  checkGameEnd() {
    if (this.player.health <= 0) {
      this.setStage(stages.PLAYER_DEATH)
    }
    if (this.enemy.health <= 0) {
      this.setStage(stages.ENEMY_DEATH)
    }
  }

  killPlayer() {
    if (this.killSent) return;
    this.killSent = true;
    this.player.death(() => this.scene.start(constants.scene.COMBAT_END, { victory: false }));
  }

  killEnemy() {
    if (this.killSent) return;
    this.killSent = true;
    this.enemy.death(() => this.scene.start(constants.scene.COMBAT_END, { victory: true }));
  }

  generateDisplayText() {
    if (this.attack.index === 0) {
      return this.attack.display;
    }
    return `<class="active">${this.attack.display.slice(0, this.attack.index)}</class><class="passive">${this.attack.display.slice(this.attack.index)}</class>`
  }

  calculateDamage() {
    let damage = this.attack.baseDamage;
    if (!this.attack.index) {
      console.log('missed');
      return 0;
    }
    if (this.attack.complete) {
      if ((this.attack.timer / 3) > this.attack.elapsedTime) {
        console.log('double bonus')
        damage += this.attack.baseDamage
      } else {
        console.log('complete bonus')
        damage += this.attack.baseDamage / 4;
      }
      if (!this.attack.missed) {
        console.log('no typo bonus')
        damage += this.attack.baseDamage / 4;
      }
    } else {
      console.log('incomplete bonus')
      damage = this.attack.baseDamage / 2;
    }
    damage -= this.attack.missed * (this.attack.baseDamage / 20);
    console.log(damage)
    if (damage < 0) {
      return 0;
    }
    return damage;
  }

  hideTimerBar() {
    this.attackBox.setVisible(false);
    this.attackTimerBar.setVisible(false);
  }

  updateTimerBar() {
    // console.log(this.attack.elapsedTime)
    const value = this.attack.elapsedTime / this.attack.timer;
    const width = (this.attackDisplay.width - 4) - ((this.attackDisplay.width - 4) * value);

    // if (!this.attackBox.visible)
    this.attackBox.clear();
    this.attackBox.fillStyle(0x222222, 0.8);
    this.attackBox.fillRect((this.width/2) - (this.attackDisplay.width/2), (this.height/2) + 20, this.attackDisplay.width, 12);
    this.attackBox.setVisible(true);

    this.attackTimerBar.clear();
    this.attackTimerBar.fillStyle(0xff0000, 1);
    this.attackTimerBar.fillRect((this.width/2) - (this.attackDisplay.width/2) + 2, (this.height/2) + 22, width, 8);
    this.attackTimerBar.setVisible(true);
  }
}
